import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  SlotAvailability,
  SlotResource,
} from '@wix/ambassador-availability-calendar/types';
import { formatRfcTimeStringToTimeSlotView } from '../../../../utils/dateAndTime/dateAndTime';
import {
  getServicePriceText,
  isOfferedAsOneTime,
} from '../../../../utils/payment/payment';
import { getSlotDuration } from '../../../../utils/duration/duration';
import { getLocationText } from '../../../../utils/bookingPreferences/bookingPreferences';
import { Optional } from '../../../../types/types';
import { getFormattedPrice } from '../../../../utils/dynamicPricing/dynamicPricing';
import {
  isFullSlot,
  isSlotWithBookingsPolicyViolation,
  isTooEarlyToBookSlot,
  isTooLateToBookSlot,
} from '../../../../utils/slotAvailability/slotAvailability';
import {
  createCtaViewModel,
  CtaViewModel,
  memoizedCtaViewModel,
} from '../ctaViewModel/ctaViewModel';
import { isDummyServices } from '../../../../api/dummyData/dummyCatalogData';
import { Service } from '@wix/bookings-uou-types';

export type AgendaSlot = {
  id: number;
  time: string;
  timeWidthHolder: string;
  serviceName: string;
  serviceIndex: number;
  spotsLeft?: string;
  duration?: string;
  location?: string;
  staffMember?: string;
  price?: string;
  policyViolation?: string;
  ctaViewModel?: CtaViewModel;
};

export type AgendaSlotsViewModel = {
  agendaSlots: AgendaSlot[];
  numberOfSlotsToDisplay?: number;
  showAllText: string;
};
export type EnrichedSlotAvailability = SlotAvailability & { id: number };

type AgendaSlotsViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  slots: EnrichedSlotAvailability[];
};

export const enrichSlotAvailability = (
  slotsAvailability?: SlotAvailability[],
) => {
  return (
    slotsAvailability?.map((slotAvailability, index) => ({
      ...slotAvailability,
      id: index,
    })) || []
  );
};

export const memoizedAgendaSlotsViewModel: MemoizedViewModalFactory<AgendaSlotsViewModel> =
  {
    dependencies: {
      settings: [
        'spotsLeftFormat',
        'spotsLeftVisibility',
        'slotDurationVisibility',
        'slotLocationVisibility',
        'slotPriceVisibility',
        'slotRegistrationStatusVisibility',
        'slotStaffMemberVisibility',
        'toEarlyToBookIndication',
        'toLateToBookIndication',
        'ctaVisibility',
        'noSpotsLeft',
        'maxTimeSlotsDisplayedPerDay',
        'limitTimeSlotsDisplay',
      ],
      state: ['servicesInView'],
      subDependencies: [memoizedCtaViewModel.dependencies],
    },
  };

export function createAgendaSlotsViewModel({
  state,
  context,
  slots,
}: AgendaSlotsViewModelParams): AgendaSlotsViewModel {
  const { servicesInView } = state;
  const { t, settingsParams, settings, businessInfo, getContent, isMobile } =
    context;
  const dateRegionalSettingsLocale = businessInfo!.dateRegionalSettingsLocale!;

  const shouldLimitSlots = settings.get(settingsParams.limitTimeSlotsDisplay);
  const numberOfSlotsToDisplay = settings.get(
    settingsParams.maxTimeSlotsDisplayedPerDay,
  );
  const isLocationVisible = settings.get(settingsParams.slotLocationVisibility);
  const isDurationVisible = settings.get(settingsParams.slotDurationVisibility);
  const isPriceVisible = settings.get(settingsParams.slotPriceVisibility);
  const isCtaVisible = settings.get(settingsParams.ctaVisibility);
  const isStaffMemberVisible = settings.get(
    settingsParams.slotStaffMemberVisibility,
  );
  const isPolicyViolationVisible = settings.get(
    settingsParams.slotRegistrationStatusVisibility,
  );

  return {
    agendaSlots:
      slots?.map<AgendaSlot>((enrichedSlot) => {
        const slot = enrichedSlot.slot!;
        const serviceIndex = servicesInView.findIndex(
          (availableService) => availableService.id === slot.serviceId,
        );
        const service = servicesInView[serviceIndex];

        const rfcStartTime = slot.startDate!;
        const rfcEndTime = slot.endDate!;

        const time = formatRfcTimeStringToTimeSlotView(
          rfcStartTime,
          dateRegionalSettingsLocale,
        );
        const timeWidthHolder = formatRfcTimeStringToTimeSlotView(
          '2023-01-01T22:48',
          dateRegionalSettingsLocale,
        );
        const serviceName = service?.info.name!;

        const location = isLocationVisible
          ? getLocationText(slot.location, t)
          : undefined;

        const staffMember = slot.resource!;
        const staffMemberName = isStaffMemberVisible
          ? staffMember.name
          : undefined;

        const shouldShowDummyDuration =
          context.flowAPI.environment.isEditor &&
          isDummyServices(servicesInView);
        const duration = isDurationVisible
          ? shouldShowDummyDuration
            ? t('dummy-content.service.duration')
            : getSlotDuration({
                dateRegionalSettingsLocale,
                rfcStartTime,
                rfcEndTime,
                t,
              }).durationText
          : undefined;

        const price = isPriceVisible
          ? getSlotPrice({
              service: service!,
              staffMember,
              context,
              state,
            })
          : undefined;

        const spotsLeft = getSpotsLeft({ slot: enrichedSlot, context });

        const policyViolation = isPolicyViolationVisible
          ? getPolicyViolationText({ context, slot: enrichedSlot })
          : undefined;

        const ctaViewModel = isCtaVisible
          ? createCtaViewModel({ state, context, slots: [enrichedSlot] })
          : undefined;

        return {
          id: enrichedSlot.id,
          time,
          timeWidthHolder,
          serviceName,
          location,
          spotsLeft,
          duration,
          price,
          staffMember: staffMemberName,
          ctaViewModel,
          policyViolation,
          serviceIndex,
        };
      }) || [],
    showAllText: getContent({
      settingsParam: settingsParams.loadMoreTimeSlots,
      translationKey: 'app.settings.defaults.time-picker.daily-agenda.show-all',
    }),
    numberOfSlotsToDisplay: shouldLimitSlots
      ? numberOfSlotsToDisplay
      : undefined,
  };
}

const getSpotsLeft = ({
  slot,
  context,
}: {
  slot: EnrichedSlotAvailability;
  context: CalendarContext;
}): AgendaSlot['spotsLeft'] => {
  const { t, settingsParams, settings, getContent } = context;

  const openSpots = slot.openSpots!;
  const isSpotsLeftVisible = settings.get(settingsParams.spotsLeftVisibility);

  if (!isSpotsLeftVisible || isSlotWithBookingsPolicyViolation(slot)) {
    return;
  }

  if (isFullSlot(slot)) {
    return getContent({
      settingsParam: settingsParams.noSpotsLeft,
      translationKey: 'app.settings.defaults.slots.no-spots-left',
    });
  }

  const customFormat = settings.get(settingsParams.spotsLeftFormat);
  if (customFormat) {
    return t('app.agenda-slot.spots-left.custom-label', {
      openSpots,
      spotsLeftFormat: customFormat,
    });
  }

  return t('app.agenda-slot.spots-left.label', {
    openSpots,
  });
};

const getSlotPrice = ({
  staffMember,
  context,
  state,
  service,
}: {
  staffMember: SlotResource;
  context: CalendarContext;
  state: CalendarState;
  service: Service;
}): Optional<string> => {
  const payment = service.payment;
  const serviceVariants = state.serviceVariantsMap?.[service.id];
  const regionalSettingsLocale = context.businessInfo!.regionalSettingsLocale!;

  if (isOfferedAsOneTime(payment)) {
    if (payment.paymentDetails.isVariedPricing) {
      return getFormattedPrice({
        state,
        context,
        service,
        choiceId: staffMember.id!,
        optionId: serviceVariants?.options?.values?.[0].id!,
      });
    }

    return getServicePriceText(payment, regionalSettingsLocale);
  }
};

const getPolicyViolationText = ({
  context,
  slot,
}: {
  context: CalendarContext;
  slot: EnrichedSlotAvailability;
}): string | undefined => {
  const { getContent, settingsParams } = context;

  if (isTooEarlyToBookSlot(slot)) {
    return getContent({
      settingsParam: settingsParams.toEarlyToBookIndication,
      translationKey: 'app.settings.defaults.policy.to-early-to-book',
    });
  }

  if (isTooLateToBookSlot(slot)) {
    return getContent({
      settingsParam: settingsParams.toLateToBookIndication,
      translationKey: 'app.settings.defaults.policy.to-late-to-book',
    });
  }
};
